<template>
  <DashboardTemplate>
    <Layout>
      <div class="text-center">
        <h3 class="font-32 mg-b-7 mg-t-1 font-weight-bold">
          ปัจจัยประเมินความพร้อม
        </h3>
        <p class="text-dark font-20 mg-y-5" v-if="!loading">
          สำหรับ{{ getNameAsseement(assessmentId) }}
        </p>
        <p class="text-grey text-center font-18 mg-y-5">
          (กรุณาเลือกด้านเพื่อทำแบบประเมิน TERAK)
        </p>
      </div>
      <div v-loading="loading">
        <div class="mg-t-3 h-min-180">
          <div
            v-for="(item, index) in categoryList"
            :key="item.id"
            class="
              factor--avatar
              bordered
              ai-center
              border-2
              w-100
              cr-pointer
              mg-b-3
              rounded
              pd-5
            "
            :class="[
              { doing: item.success > 0 && item.success != item.question },
              { success: item.success == item.question },
            ]"
            @click="
              item.question == item.success
                ? $router.push(
                    `/level?assessment=${assessmentId}&cate=${
                      item.id
                    }&question=${
                      item.questionsId[item.success - 1].questionId
                    }&number=${item.success}`
                  )
                : $router.push(
                    `/level?assessment=${assessmentId}&cate=${
                      item.id
                    }&question=${
                      item.questionsId[item.success].questionId
                    }&number=${item.success + 1}`
                  )
            "
          >
            <div
              class="circle-box"
              :class="[
                { doing: item.success > 0 },
                { success: item.success == item.question },
              ]"
            >
              <img
                class="mw-100"
                :src="require(`@/assets/assessment${index + 1}.png`)"
                alt=""
              />
            </div>

            <div class="w-100" style="flex: 1">
              <div class="mg-t-7 pd-l-5">
                {{ item.name }}
              </div>
              <div class="pd-l-5">
                <el-row :gutter="20" type="flex" align="middle">
                  <el-col :xs="19" :span="20" :offset="0"
                    ><el-progress
                      class="w-100 mg-t-7"
                      :show-text="false"
                      :percentage="getPercen(item.success, item.question)"
                      :status="item.success == item.question ? 'success' : null"
                    ></el-progress
                  ></el-col>
                  <el-col
                    :xs="5"
                    :span="4"
                    :offset="0"
                    :class="[
                      { 'text-primary': item.success > 0 },
                      { 'text-success': item.success == item.question },
                    ]"
                    >{{ item.success }}/{{ item.question }}</el-col
                  >
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div class="mg-t-3 text-center">
          <el-button
            v-if="allowSentAssessment && !loading"
            type="primary"
            class="wid220"
            @click="confirmDialog = !confirmDialog"
            >ส่งแบบประเมิน</el-button
          >
        </div>
        <el-dialog :visible.sync="confirmDialog" class="small">
          <div class="text-center">
            <h3 class="mg-t-5 font-weight-bold">
              คุณต้องการยืนยันการส่งแบบประเมิน?
            </h3>
            <p class="text-grey mg-y-6">หากคุณกดส่งแบบประเมินแล้ว</p>
            <p class="text-grey mg-y-6">
              จะไม่สามารถกลับมาแก้ไขข้อมูลที่กรอกไปแล้วได้
            </p>
          </div>

          <div slot="footer" class="dialog-footer">
            <div class="is-flex js-between ai-center">
              <a
                @click="confirmDialog = false"
                class="text-grey-light font-20 mg-l-7"
                >ยกเลิก</a
              >
              <div>
                <el-button
                  id="sendAssessment"
                  type="primary"
                  :loading="loadingConfirm"
                  @click="confirmAssessment"
                  >ยืนยัน</el-button
                >
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </Layout>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardTemplate";
import { HTTP } from "@/service/axios";
import Layout from "@/template/LayoutDashboardTemplate";
import "@/service/Auth";
import "@/service/Company";
export default {
  components: {
    DashboardTemplate,
    Layout
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    },
    allowSentAssessment() {
      let status = true;
      this.categoryList.map(item => {
        if (item.success < item.question) {
          status = false;
        }
      });
      return status;
    }
  },
  data() {
    return {
      categoryList: [],
      assessmentId: "",
      loading: true,
      confirmDialog: false,
      loadingConfirm: false
    };
  },
  async mounted() {
    await this.fetchAsseement();
  },
  methods: {
    formatProgressText(item) {
      return `${item}`;
    },
    getPercen(number = 0, total = 0) {
      let percen = 0;
      percen = (number * 100) / total;
      return percen;
    },
    confirmAssessment() {
      this.loadingConfirm = true;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`confirm/assessment`)
        .then(async res => {
          if (res.data.success) {
            let userData = await this.getUserById(this.token);
            if (userData.data.success) {
              this.$store.commit(
                "SET_USER",
                JSON.parse(JSON.stringify(userData.data.obj))
              );
            }
            window.scrollTo(0, 0);
            this.$router.push("/send-success");
          } else {
            this.alertFailError();
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("confirmAssessment error", e);
        })
        .finally(() => {
          this.loadingConfirm = false;
        });
    },
    async fetchAsseement() {
      try
      {
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        let res = await HTTP.get(`choose/asseement`)
          .catch(e => {
            if (e == "Error: Request failed with status code 401") {
              this.checkAuth();
            } else {
              if (e != "Error: timeout of 120000ms exceeded") {
                this.alertCatchError(e);
              }
            }
            console.log("fetchAsseement error", e);
          })
          .finally(() => {
            this.loading = false;
          });

        if (res.data.success) {
          this.categoryList = res.data.obj.cate;
          this.assessmentId = res.data.obj.assessmentId;
        } else if (res.data.status == 401) {
          this.checkAuth();
        } else {
          let checkData = await this.checkCompanyData(this.user);
          if (checkData) {
            let url = "/edit-company";
            typeof this.$route.query.code != "undefined"
              ? (url = url + "?code=" + this.$route.query.code)
              : null;
            this.$router.push(url).catch(err => {});
          }
        }
      } catch {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
        console.log("fetchAsseement error", e);
      }
    },
    getNameAsseement(id) {
      let name = "";
      switch (id) {
        case 1:
          name = "ธุรกิจขนาดเล็ก";
          break;
        case 2:
          name = "ธุรกิจขนาดกลาง";
          break;
        case 3:
          name = "ธุรกิจขนาดใหญ่";
          break;
        default:
          break;
      }

      return name;
    }
  }
};
</script>
